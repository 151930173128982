import React from "react";


const HomeAG = React.lazy(() => import('./pages/AG/index'));
const Consulenza = React.lazy(() => import('./pages/Consulenza/index'));
const PageContactDetail = React.lazy(() => import("./pages/Pages/Contact/PageContactDetail"));
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));



const routes = [

  { path: "/contact", component: PageContactDetail },
  { path: "/call", component: Consulenza },
  { path: "/", component: HomeAG, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },

];

export default routes;
