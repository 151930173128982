import React, { useEffect, Suspense } from "react";
import { withRouter } from "react-router-dom";
// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const Footer = React.lazy(() => import("./Footer"));




const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
function Layout(props) {
  useEffect(() => {
    document.body.classList = "";
    window.addEventListener("scroll", scrollNavigation, false);
    return () => {
      window.removeEventListener("scroll", scrollNavigation, false);
    }
  }, [])



  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById('topnav').classList.add('nav-sticky');
    }
    else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }
  }

  return (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <Topbar hasDarkTopBar={props.hasDarkTopBar} />

        {props.children}
        <Footer />

      </Suspense>
    </React.Fragment>
  );

}

export default withRouter(Layout);
