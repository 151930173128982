import React, { Component, Suspense, useEffect } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

import "./assets/css/colors/default.css";

// Include Routes
import routes from "./routes";


import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import it from './languages/it.json';
import en from './languages/en.json';
import ReactGA from 'react-ga';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      it: { translation: it },
      en: { translation: en }

    },
    lng: navigator.languages[0],
    fallbackLng: "en",

    interpolation: { escapeValue: true }
  });



function withLayout(WrappedComponent, hasDarkTopBar) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

function App() {


  useEffect(() => {
    const location = window.location
    console.log("alfonsograziano.it".indexOf("localhost") === -1)
    console.log(location.href, location.href.indexOf("localhost") === -1)
    if (location.protocol !== 'https:' && location.href.indexOf("localhost") === -1) {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }

    ReactGA.initialize('UA-116657052-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [])

  const Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Router>
        <Suspense fallback={Loader()}>
          <Switch>
            {routes.map((route, idx) =>
              route.isWithoutLayout ? (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={idx}
                />
              ) : (
                <Route
                  path={route.path}
                  exact
                  component={withLayout(route.component, route.isTopbarDark)}
                  key={idx}
                />
              )
            )}
          </Switch>
        </Suspense>
      </Router>
    </React.Fragment>
  );

}

export default withRouter(App);
